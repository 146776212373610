import React, { useEffect, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import Image from 'next/image';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

import Checkers from '../../../../helpers/class/Checkers';
import SwiperColorClasses from '../../../plugins/swiperUtils';
import ViewPort from '../../../ViewPort';

// Import Swiper styles
import 'swiper/css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft as fasAngleLeft, faAngleRight as fasAngleRight} from "@fortawesome/pro-solid-svg-icons";
import Link from "next/link";

// install Swiper modules
SwiperCore.use([ Autoplay, Navigation, Pagination, Lazy ]);

export default function CollectionsType ( props ) {

  const slidesPerView = props.hasOwnProperty('slidesPerView') ? props.slidesPerView : 3

  const childRef = useRef();

  const swiper_options = {
    mode: 'dark',
    pagination: true
  };

  useEffect(() => {
    new SwiperColorClasses(swiper_options).swiperOptions();
  });

  const builder = props.builder;
  const content = props.content;
  const articles = props.articles;

  let gallery_item_class = 'panel-item no-padding hover-zoom bg-transparent h-100 w-100\ ';
  let container_class = 'container';
  if (props.type == 'blog') {
    if (Checkers.isValidString(builder.builder_news_view_width)) {
      container_class = builder.builder_news_view_width;
    }
    gallery_item_class += Checkers.isValidObjString(builder, 'builder_news_radius') ? `border-radius-${builder.builder_news_radius}` : '';

  } else if (props.type == 'category') {
    if (Checkers.isValidString(content.category_view_width)) {
      container_class = content.category_view_width;
    }
    gallery_item_class += Checkers.isValidObjString(content, 'tiles_radius') ? `border-radius-${content.tiles_radius}` : '';

  }

  let skeletonArticles = [];
  for (let i = 0; i < 6; i++) {
    skeletonArticles.push(
        <Skeleton key={i.toString()} height={460} width={352} className="b-rad-md"/>
    );
  }

  const navigation = {
    enabled: true,
    nextEl: '.swiper-custom-next',
    prevEl: '.swiper-custom-prev'
  }

  return (
      <div className={`${container_class} pb-2 p-0 collections-swiper`}>
        <div className="row w-100 m-0">
          <div className="col-12 p-0">
            <Swiper
                className={'p-b-60'}
                speed={1000}
                spaceBetween={slidesPerView>3 ? 15 : 40}
                navigation={true}
                pagination={{
                  dynamicBullets: true,
                  clickable: true
                }}
                autoplay={false}
                // className="px-5 pb-5"
                breakpoints={{
                  640: {
                    slidesPerView: 1
                  },
                  768: {
                    slidesPerView: 2
                  },
                  991: {
                    slidesPerView: slidesPerView
                  },
                }}
            >
              {/*<>*/}
              {/*  <div*/}
              {/*      className={'swiper-button-prev swiper-custom-prev\ ' + `${Checkers.isValidBoolean(navigation) ? 'hidden' : ''}`}>*/}
              {/*    <FontAwesomeIcon icon={fasAngleLeft} className="text-master fs-16"/>*/}
              {/*  </div>*/}
              {/*  <div*/}
              {/*      className={'swiper-button-next swiper-custom-next\ ' + `${Checkers.isValidBoolean(navigation) ? 'hidden' : ''}`}>*/}
              {/*    <FontAwesomeIcon icon={fasAngleRight} className="text-master fs-16"/>*/}
              {/*  </div>*/}
              {/*</>*/}
              {props.loadingArticles ?
                  skeletonArticles
                  : articles.map((article) => {
                    const created = moment(new Date((article.created) * 1000)).format('dddd Do MMMM');
                    const title = props.articlesContentType == 'blog' ? created : article.title;
                    const subtitle = props.articlesContentType == 'article' ? article.subtitle : props.articlesContentType == 'blog' ? article.title : article.description;

                    return (
                        <ViewPort
                            // parentClassName={col_class}
                            key={article.id.toString()}
                            skeletonItem={
                              <Skeleton height={460} width={352} className="b-rad-md"/>
                            }
                        >
                          <SwiperSlide
                              key={article.id.toString()}
                              className={props.setClass(article, '')}
                          >

                            <Link href={props.updateHref( article )}>
                              <a className={gallery_item_class}
                                 onClick={() => props.clickHandler( article )}>
                                <div className="inner bottom-left text-left padding-30">
                                  <button
                                      className="btn-sm bg-white border-0 rounded-pill text-danger text-design-color fs-11 m-b-10">
                                    {title}
                                  </button>
                                  {
                                    Checkers.isValidString(subtitle) ?
                                        <h5 className="text-white text-left line-clamp-2 m-0 fw-normal">{subtitle}</h5>
                                        :
                                        <></>
                                  }
                                </div>
                                <div className={"ar-1-1 bg-master-dark brightness-filter-80 " + (Checkers.isValidString(props.itemClassName) ? props.itemClassName : '')}
                                     style={{minHeight: 450, height: '100%'}}>
                                  <div className="psuedo-background ng-scope" data-pages-bg-image>
                                    {
                                      Checkers.isValidString(article.image) ?
                                          <Image
                                              className="psuedo-background-img"
                                              layout={'fill'}
                                              sizes={'75vw'}
                                              quality={'75'}
                                              src={article.image}
                                              alt={'Post Img'}
                                              placeholder={'blur'}
                                              blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                          />
                                          :
                                          Checkers.isValidArray(article.images) ?
                                              <Image
                                                  className="psuedo-background-img"
                                                  layout={'fill'}
                                                  sizes={'75vw'}
                                                  quality={'75'}
                                                  src={article.images[0]}
                                                  alt={'Post Img'}
                                                  placeholder={'blur'}
                                                  blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                                              />
                                              :
                                              <></>

                                    }
                                  </div>
                                </div>
                              </a>
                            </Link>
                          </SwiperSlide>
                        </ViewPort>
                    )
                  })
              }
            </Swiper>
          </div>
        </div>
      </div>
  )
}
